<template>
  <div class="wrapper">
    <h3>Pod # {{podInformation.podId}}</h3>
    <h6>{{ podInformation.places.length }}/{{podInformation.count}} People</h6>
    <article class="pod-guests">
      <main v-for="(user, i) in podInformation.usernames" :key="i">
        <div
            class="guest"
        >
          <div class="guest-circle">
            <v-btn icon>
            </v-btn>
          </div>

          <div class="guest-name">
            {{ user || 'Guest ' }}
          </div>

        </div>
      </main>
    </article>
  </div>
</template>

<script>

export default {
  props: {
    podInformation: {type: Object, default: null},
    selectedPod: {
      type: Number,
      default: null
    },
    users: {
      type: Array, default: () => []
    }
  },
  components: {}
}
</script>

<style lang="scss" scoped>
.wrapper {
  min-width: 230px;
  margin-top: 20px;
  .v-btn::v-deep span{
    font-weight: bold !important;
    font-size: 10px !important;
    line-height: 15px !important;
    text-align: center;
    color: #FFFFFF;

  }
  .join-btn {
    background-color: #FF3C11;
    padding: 0 50px;
    margin-top: 20px;
    min-width: 235px;

    &::v-deep span {
      font-weight: 500 !important;
    }
  }

  .pod-guests {
    margin-top: 20px;
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 165px;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-auto-rows: auto;
    flex-grow: 1;

    &::-webkit-scrollbar {
      background: rgba(216, 216, 216, 0.40879);
      border-radius: 1.5px;
      width: 3px;
    }

    &::-webkit-scrollbar-thumb {
      background: #D8D8D8;
    }
  }

  h3 {
    font-weight: 500;
    font-size: 25px;
    letter-spacing: 0;
    margin-bottom: 0;
  }

  .guest {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: baseline;
    padding: 0 3px;
    margin-bottom: 10px;
    transition: border-color 0.4s;
    margin-top: 10px;
  }

  .guest-circle {
    border: 2px solid rgba($color: #000000, $alpha: 0.0);
    background: #929292;
    width: 36px;
    height: 36px;
    border-radius: 50%;
  }

  h6 {
    font-weight: 200;
    font-size: 20px;
    letter-spacing: 3.68px;
    text-transform: uppercase;
  }
}
</style>