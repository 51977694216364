<template>
  <div class="choose-pod--page">
    <page-offset />
    <h1 class="page-title">CHOOSE A POD</h1>

    <div
      class="
        choose-pod
        d-flex
        pa-5
        flex-sm-column flex-lg-row
        align-sm-center align-lg-start
      "
    >
      <span
        class="back-btn text-no-wrap pr-3"
        style="flex: 0"
        @click="$router.push('/tickets')"
      >
        <v-icon>mdi-arrow-left</v-icon>
        Back
      </span>
      <div :class="[isMobile ? 'choose-pod__map-mobile' : 'choose-pod__map']">
        <pod-map
          :reservedPodId="reservedPodId"
          :isMobile="isMobile"
          @selectedPod="getSelectedPod"
        ></pod-map>
        <span
          v-if="!joinPod && !selectedPod"
          style="display: block"
          class="d-block empty-reserved-pod"
          >Use the map to select your digital pod</span
        >
      </div>

      <div class="right-content">
        <p v-html="title" class="choose-pod__title" />
        <p v-html="date" class="choose-pod__date" />
        <read-more
          class="choose-pod__description"
          more-str="Read more"
          :text="description"
          link="#"
          less-str="Read less"
          :max-chars="1000"
        ></read-more>

        <div class="d-flex align-center justify-space-between btn-group">
          <v-btn
           :loading="loading"
           :disabled="!selectedPod"
            v-if="!joinPod && !isAdmin && userName"
            @click="joinToThePod()"
            outlined
            class="choose-pod__btn"
            height="54px"
          >
            BOOK A TICKET
          </v-btn>
          <v-btn
            v-if="!userName"
            @click="move('/auth')"
            outlined
            class="choose-pod__btn"
            height="54px"
          >
            BOOK A TICKET
          </v-btn>
        </div>

        <div class="selected-pod-on-map">
          <div v-if="podInformation && selectedPod" class="pod-list">
            <purchase-pod-list
              :podInformation="podInformation"
              :selectedPod="selectedPod"
            ></purchase-pod-list>
          </div>

          <v-btn v-if="podIsFull" text class="full-pod" height="54px">
            POD IS FULL
          </v-btn>
          <span class="congrats" v-if="podIsFull"
            >PLEASE CHOOSE ANOTHER POD ON THE MAP</span
          >

          <span class="congrats" v-if="joinPod">
            CONGRATULATIONS! YOU’VE BOOKED PLACE ON POD #{{
              this.reservedPodId
            }}
            CLICK BUTTON BELOW TO JOIN THE SHOW PAGE</span
          >
          <v-btn outlined
            v-if="joinPod || isAdmin"
            class="choose-pod__btn"
            height="54px"
            @click="move(`${joinURL}`)"
          >
            {{ joinText }}
          </v-btn>
        </div>
        <div>
          <v-snackbar
            v-model="snackbar"
            :multi-line="multiline"
            :timeout="timeout"
            :centered="centered"
            :color="notificationColor"
          >
            {{ notification }}
            <template v-slot:action="{ attrs }">
              <v-btn
                text
                color="red"
                center
                v-bind="attrs"
                @click="snackbar = false"
              >
                Close
              </v-btn>
            </template>
          </v-snackbar>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageOffset from "../layout/PageOffset.vue";
import PodMap from "@/components/tickets/PodMap";
import PurchasePodList from "@/components/tickets/PurchasePodList";
import request from "@/plugins/request";
import awsAuth from "@/cognito/aws-auth";

/* eslint-disable no-unused-vars */
export default {
  components: { PurchasePodList, PodMap, PageOffset },
  watch: {
    windowWidth(oldVal, newVal) {
      this.isMobile = newVal <= 800;
    },
  },
  data() {
    return {
      loading: false,
      isAdmin: awsAuth.isAdmin(),
      podIsFull: false,
      userName: "",
      reservedPodId: null,
      joinPod: false,
      started: false,
      isMobile: false,
      podInformation: null,
      snackbar: false,
      centered: true,
      multiline: true,
      timeout: 4500,
      notification: null,
      notificationColor: "",
      concertId: 9987,
      windowWidth: window.innerWidth,
      selectedPod: null,
      joinText: "TO THE SHOW PAGE",
      joinURL: "/shows",
      title: "JOHN WHITMORE",
      date: "SATURDAY, MAY 9, 2022 STARTS AT 5:30PM",
      description: "Use the map to select your digital pod",
    };
  },
  methods: {
    async joinToThePod() {
      this.loading = true;
      if (this.selectedPod) {
        const places = this.podInformation.places;
        let placeId = places.length + 1;

        await request({
          method: "POST",
          url: `/show/api/v1/Pod/ReservedPods?showId=${this.concertId}&podId=${this.selectedPod}&placeId=${placeId}`,
        })
          .then(() => {
            return this.podInfo();
          })
          .then((podInfo) => {
            this.podInformation = podInfo.data;
            this.joinPod = this.isUserJoinedThePod();
            this.reservedPodId = this.selectedPod;
            this.getConcertState();
            this.loading = false;
          })
          .catch((e) => {
            console.log(e);
            this.snackbar = true;
            this.notificationColor = "red darken-4";
            this.notification = `Sorry, but all places on this pod are taken. Please, choose another pod`;
          });
      }

      return Promise.resolve();
    },
    isUserJoinedThePod() {
      return !!this.podInformation.places.find(
        (p) => p.userId === awsAuth.getUserTokens().userId
      );
    },
    getReservedPods() {
      return request({
        method: "GET",
        url: `/show/api/v1/Pod/GetReservedPods`,
      });
    },
    podInfo() {
      return request({
        method: "GET",
        url: `/show/api/v1/Pod/GetPod?showId=${this.concertId}&podId=${this.selectedPod}`,
      });
    },
    async handlePodInfo() {
      if (this.selectedPod) {
        const podInfo = await this.podInfo();
        this.podInformation = podInfo.data;
        this.podIsFull =
          this.podInformation.count - this.podInformation.places.length === 0;
        this.joinPod = this.isUserJoinedThePod();
      } else {
        this.joinPod = false;
      }
    },
    onResize() {
      this.windowWidth = window.innerWidth;
      this.isMobile = this.windowWidth <= 800;
    },
    getSelectedPod(selectedPod) {
      this.selectedPod = selectedPod;
      this.handlePodInfo();
      if (this.isAdmin) {
        this.joinText = this.started ? "ENTER THE SHOW" : "TO THE SHOW PAGE";
        this.joinURL = this.started
          ? `/pod/${this.isAdmin ? this.selectedPod : this.reservedPodId}`
          : "/shows";
      }
    },
    async getConcertState() {
      const response = await request({
        method: "GET",
        url: "/concert/api/v1/Manager/GetConcertList",
      });
      this.selectedPod =
        this.isAdmin && !this.selectedPod ? 1 : this.selectedPod;
      console.log(this.selectedPod);
      if (response.status === 200) {
        request({
          method: "GET",
          url: "/concert/api/Nats/GetSnapshotByConcertId?concertId=9987",
        }).then((res) => {
          const startTime = new Date(res.data.start);
          const currentTime = new Date();

          const differenceTime = startTime.getTime() - currentTime.getTime();

          if (differenceTime > 900000) {
            this.joinText = "TO THE SHOW PAGE";
            this.joinURL = "/shows";
            this.started = false;

            const timer = differenceTime - 900000;
            this.updateConcertState(timer, response);
          } else {
            this.joinText =
              response.data.length != 0 ? "ENTER THE SHOW" : "TO THE SHOW PAGE";
            this.joinURL =
              response.data.length != 0
                ? `/pod/${this.isAdmin ? this.selectedPod : this.reservedPodId}`
                : "/shows";
            this.started = response.data.length != 0;
          }
        });
      }
    },
    updateConcertState(timer, response) {
      setTimeout(() => {
        this.joinText =
          response.data.length != 0 ? "ENTER THE SHOW" : "TO THE SHOW PAGE";
        this.joinURL =
          response.data.length != 0
            ? `/pod/${this.isAdmin ? this.selectedPod : this.reservedPodId}`
            : "/shows";
        this.started = response.data.length != 0;
      }, timer);
    },
    move(to) {
      this.$router.push(to);
    },
  },
  mounted() {
    this.$nextTick(() => {
      awsAuth.authenticated().then((response) => {
        awsAuth.refreshJWTToken().then((result) => {
          this.userName = response.username;
          this.getReservedPods()
            .catch((e) => console.log(e))
            .then((d) => {
              const data = d.data;
              this.reservedPodId = data[0]?.podId;
              this.getConcertState();
            });
        });
      });
    });

    this.isMobile = this.windowWidth <= 800;
    window.addEventListener("resize", this.onResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
};
/* eslint-enable no-unused-vars */
</script>

<style lang="scss" scoped>

.back-btn{
  display:none;
}
.choose-pod__title{
  font-size:30px!important;
  line-height: 1!important;
}
.choose-pod__date{
  font-size:18px!important;
  line-height: 1.5!important;
}

#app .page-title {
  font-weight: 500;
  font-size: 50px;
  line-height: 137px;
  text-align: center;
  letter-spacing: 0;
  color: #ffffff;
  padding: 2em 0 0.5em;

  @media screen and (max-width: 850px) {
    padding: 0.5em;
    font-size: 50px;
    letter-spacing: 1px;
    line-height: 57px;
  }
}

#app .choose-pod {
  // background: url("~@/assets/05_VV_Homepage_02.png");
  background-size: cover;
  margin-bottom: -10px;
  padding-bottom: 170px !important;

  @media screen and(max-width: 850px) {
    flex-direction: column;
  }

  & > * {
    @media screen and(min-width: 850px) {
      flex: 0.45;
    }
  }

  .right-content {
    margin-top: 50px;
    @media screen and (max-width: 600px) {
      text-align: center;
    }
  }

  &__map {
    margin-top: 50px;
    background-size: 100% !important;
    height: calc(50vw - 82px);
  }

  &__map-mobile {
    background-size: 98% !important;
    width: 350px;
    margin: 0 auto !important;
  }

  .congrats {
    padding-top: 20px;
    font-weight: bold;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 2px;
    color: #ffffff;
  }

  &__map,
  &__map-mobile {
    background: url("~@/assets/download.png") no-repeat;
    margin-right: 60px;
    border-radius: 16px;
    font-weight: 500;
    font-size: 80px;
    line-height: 119px;
    text-align: center;
    letter-spacing: 0;
    color: #878787;
    max-width: 807px;

    @media screen and(max-width: 850px) {
      margin: 0 0 20px;
    }
  }

  &__title {
    font-weight: 500;
    font-size: 50px;
    line-height: 58px;
    letter-spacing: 0;
    margin-bottom: 0;

    @media screen and(max-width: 850px) {
      font-weight: 400;
      font-size: 40px;
      line-height: 45px;
      letter-spacing: 2px;
    }
  }

  &__date {
    font-weight: 200;
    font-size: 40px;
    line-height: 59px;
    letter-spacing: 3.68px;
    text-transform: uppercase;

    @media screen and(max-width: 850px) {
      font-size: 22px;
      line-height: 50px;
      letter-spacing: 2px;
    }
  }

  &__description {
    margin-top: 1em;
    font-weight: 200;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 2.7px;

    ::v-deep span {
      a {
        color: #fff;
      }
    }

    @media screen and(max-width: 850px) {
      margin-top: 0;
    }
  }

  .btn-group {
    @media screen and(max-width: 500px) {
      flex-direction: column;
      .choose-pod__btn {
        max-width: 220px;
      }
    }
  }

  &__btn {
    background-color: rgba(255, 255, 255, 0.13);;
    padding: 0 50px;
    margin-top: 20px;
    display: block;

    &::v-deep span {
      font-weight: 700;
      font-size:14px;
    }
  }

  .empty-reserved-pod {
    font-family: Oswald;
    font-style: normal;
    text-align: center;
    display: block;
    padding-left: 10px;
    color: #ff3c11;
    font-weight: 300;
    font-size: 30.0797px;
  }

  .full-pod {
    background: #97b5c9;
    padding: 0 50px;
    display: block;
    margin-top: 20px;
    &::v-deep span {
      font-weight: 500;
    }
  }
}
.back-btn {
  cursor: pointer;
  font-size: 30px;
  font-weight: 300;
  letter-spacing: 2px;

  .v-icon {
    font-size: 30px;
  }

  @media screen and(max-width: 1264px) {
    position: absolute;
    left: 20px;

  }
}
@media screen and(max-width: 850px) {
    .btn-group{
      justify-content: center!important;
    }
    .empty-reserved-pod{
      line-height: 1.5!important;
    }
    .choose-pod__title{
      line-height: 1.5!important;
    }

    .choose-pod--page{
      padding-top:90px;
    }
  }
  @media screen and(min-width: 792px) and (max-width: 850px) {
    .right-content{
          margin-top:300px!important;
    }
  }
</style>
